import type { CartItem } from 'src/sdk/cart'

import { handleOrderKpiData } from './handleOrderKpiData'

const featuresKpis = [
  {
    type: 'comparedProduct',
    appName: 'compared-product-data',
    marketingTag: 'compared-product',
  },
]

export const getFeatureKpi = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window?.location?.search)

    return featuresKpis?.find((item) => params?.has(item?.type))
  }

  return false
}

export const kpiOrderBySearchParams = (
  product: CartItem[] | null,
  cartId: string
) => {
  const featureKpi = getFeatureKpi()

  if (featureKpi && product) {
    handleOrderKpiData({
      cartId,
      products: product,
      appName: featureKpi?.appName,
      marketingTag: featureKpi?.marketingTag,
    })

    return true
  }

  return false
}
