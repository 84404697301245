import { RadioGroup, RadioOption } from '@faststore/ui'
import React, { useState } from 'react'
import Button from 'src/components/ui/VivaraButton'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { ProductType } from 'src/components/product/ProductCard/ProductCardTypes'
import getWishlistIndexById from 'src/utils/wishlist/getWishlistIndexById'

import BaseModal from '../BaseModal'

import './add-to-wishlist-modal.scss'

type NewWishListType = {
  product: BrowserProductQueryQuery['product'] | ProductType
  chosenOption: number
  productSize: number
}

interface AddToWishlistModalProps {
  products?: Array<BrowserProductQueryQuery['product'] | ProductType>
  productIndex?: number
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  openWishlistModal: () => void
  finalAction?: () => void
  typeAction?: string
  hideCurrent?: boolean
}

const AddToWishlistModal = ({
  products,
  productIndex,
  isOpen,
  setIsOpen,
  finalAction,
  openWishlistModal,
  typeAction,
  hideCurrent,
}: AddToWishlistModalProps) => {
  const { lists, currentWishlist, moveWishlistProduct, addProductToWishlist } =
    useWishlistContext()

  const [chosenOption, setChosenOption] = useState(-1)
  const indexCurrentWishlist = getWishlistIndexById(currentWishlist, lists)
  const onCreateListClick = () => {
    setIsOpen(false)
    openWishlistModal()
  }

  const onMoveProductClick = () => {
    if (productIndex !== undefined) {
      moveWishlistProduct(productIndex, chosenOption)
    } else if (products?.length) {
      const newWishList: NewWishListType[] = []

      products.forEach((product) => {
        const sizeItem = product?.additionalProperty?.[0]?.value
        const wishlistProduct = {
          product,
          chosenOption,
          productSize: sizeItem ? sizeItem.toString() : '',
        }

        newWishList.push(wishlistProduct)
      })
      addProductToWishlist(newWishList)
    }

    setIsOpen(false)
    finalAction?.()
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const option = parseInt(e.currentTarget.value, 10)

    setChosenOption(option)
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}
      className="move-wishlist-modal"
    >
      <h5 className="move-wishlist-modal__header">
        {`Para qual das listas você deseja ${
          typeAction === 'changeProductWishlist' ? 'mover' : 'adicionar'
        } este produto?`}
      </h5>
      <div className="move-wishlist-modal__radio-group">
        <RadioGroup
          name="radio-group"
          selectedValue={chosenOption}
          onChange={onValueChange}
        >
          <div className="move-wishlist-modal__options">
            {lists?.map((list, index) => (
              <RadioOption
                key={index}
                value={index}
                label="measure"
                disabled={hideCurrent && index === indexCurrentWishlist}
              >
                <div className="move-wishlist-modal__option">
                  <span>{list.name}</span>
                </div>
              </RadioOption>
            ))}
          </div>
        </RadioGroup>
      </div>
      <div className="move-wishlist-modal__btn-container">
        <Button
          variant="dark"
          disabled={chosenOption < 0}
          onClick={onMoveProductClick}
        >
          {`${
            typeAction === 'changeProductWishlist' ? 'Mover' : 'Adicionar'
          } produto`}
        </Button>
        <Button variant="outlined" onClick={onCreateListClick}>
          Criar Lista
        </Button>
      </div>
    </BaseModal>
  )
}

export default AddToWishlistModal
