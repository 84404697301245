import { useEffect, useState } from 'react'
import type { CartItem } from 'src/sdk/cart'
import { useCart } from 'src/sdk/cart'
import { handleOrderKpiData } from 'src/utils/kpiToCart/handleOrderKpiData'
import { kpiOrderBySearchParams } from 'src/utils/kpiToCart/kpiOrderBySearchParams'

interface IUseKpiOrderAddToCart {
  productCart: CartItem[] | null
  appName?: string
  marketingTag?: string
  byParams?: boolean
}

const useKpiOrderAddToCart = ({
  productCart,
  appName,
  marketingTag,
  byParams,
}: IUseKpiOrderAddToCart) => {
  const [clickAddToCart, setClickAddToCart] = useState(false)
  const { id } = useCart()

  const actionKpiToCart = async () => {
    setClickAddToCart(false)

    if (byParams) {
      kpiOrderBySearchParams(productCart, id)
    } else {
      await handleOrderKpiData({
        cartId: id,
        products: productCart,
        appName,
        marketingTag,
      })
    }
  }

  useEffect(() => {
    if (id && clickAddToCart) {
      actionKpiToCart()
    }
  }, [clickAddToCart, id])

  return {
    setClickAddToCart,
  }
}

export default useKpiOrderAddToCart
